import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const PolicyTemplate = ({ data }) => {
	const { wpPolicy } = data;
	const { siteUrl } = data.site.siteMetadata;

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<GatsbySeo
					title={wpPolicy.title}
					description={wpPolicy.title}
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}${wpPolicy.uri}`,
						title: `${wpPolicy.title}`,
						description: `${wpPolicy.title}`,
					}}
				/>

				<Container className="my-6">
					<Row>
						<Col>
							<div className="policy">
								{parse(wpPolicy.policyContent.content)}
							</div>
						</Col>
					</Row>
				</Container>
			</Layout>
		);
	}
};

export default PolicyTemplate;

export const blogData = graphql`
	query ($id: String!) {
		wpPolicy(id: { eq: $id }) {
			title
			uri
			policyContent {
				content
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
